import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { API } from "aws-amplify";
import axios from "axios";

import "./MixUploadDropZone.css";

const uuidv1 = require("uuid/v4");

const activeStyle = {
  borderColor: "#2196f3",
  borderWidth: "1px",
  borderStyle: "dashed",
};

const acceptStyle = {
  borderColor: "#00e676",
  borderWidth: "1px",
  borderStyle: "dashed",
};

const rejectStyle = {
  borderColor: "#ff1744",
  borderWidth: "1px",
  borderStyle: "dashed",
};

function MixUploadDropZone(props) {
  // const [progress, setProgress] = useState(0);

  const [uploads, setUploads] = useState([]);
  const { onUploadProgress } = props;

  useEffect(() => {
    if (onUploadProgress) {
      onUploadProgress(uploads);
    }
  }, [uploads, onUploadProgress]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        // First, we need to request permission to upload the file
        // We do this by querying the mix upload endpoint
        const params = {
          body: {
            filename: file.name,
            extension: file.name.split(".").pop(),
            source: "4",
          },
        };
        file.id = uuidv1();
        file.state = "Requesting permission to upload";
        uploads.push(file);
        setUploads([...uploads]);

        API.post("mix", "/upload", params)
          .then((response) => {
            console.log("Permission Response: ", response);
            const formData = new FormData();
            for (var fieldKey in response.fields) {
              console.log("Appending " + fieldKey + " = " + response.fields[fieldKey]);
              formData.append(fieldKey, response.fields[fieldKey]);
            }
            console.log("Form Fields: ", formData);

            // Append the file to the form and upload it
            formData.append("file", file);

            // Perform the POST to S3
            const config = {
              headers: { "content-type": "multipart/form-data" },
              onUploadProgress: (progressEvent) => {
                var percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (percent >= 100) {
                  // setProgress(100);
                  file.progress = 100;
                } else {
                  // setProgress(percent);
                  file.progress = percent;
                }

                var foundIndex = uploads.findIndex((upload) => upload.id === file.id);
                uploads[foundIndex] = file;
                setUploads([...uploads]);
              },
            };

            file.state = "Uploading file";
            file.trackID = response.track.id;
            var foundIndex = uploads.findIndex((upload) => upload.id === file.id);
            uploads[foundIndex] = file;
            console.log("Starting upload and setting uploads to: ", uploads);
            setUploads([...uploads]);

            axios
              .post(response.url, formData, config)
              .then((response) => {
                console.log(response);

                file.state = "Done";
                var foundIndex = uploads.findIndex((upload) => upload.id === file.id);
                uploads[foundIndex] = file;
                setUploads([...uploads]);

                setTimeout(function () {
                  var foundIndex = uploads.findIndex((upload) => upload.id === file.id);
                  uploads.splice(foundIndex, 1);
                  setUploads([...uploads]);
                }, 3000);
              })
              .catch((error) => {
                console.log("Failed to upload file: ", error);
              });
          })
          .catch((error) => {
            console.log("Upload Permission API Error: ", error);
          });
      });
    },
    [uploads]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: "audio/*",
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  return (
    <section className="MixUploadDropZone">
      <div {...getRootProps({ className: "dropzone", style })}>
        {props.children}
        <input {...getInputProps()} />
        <p className="uploadExplainer">Drag 'n' drop audio files here, or click to select files to upload</p>
      </div>
    </section>
  );
}

export default MixUploadDropZone;
