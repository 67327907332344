import React, { useState } from "react";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";

export default function SignUpConfirm(props) {
  const { email, password } = props;

  const [loading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");

  function validateForm() {
    return confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);

    try {
      await Auth.confirmSignUp(email, confirmationCode);
      await Auth.signIn(email, password);
      props.history.push("/");
    } catch (e) {
      alert(e.message);
      setLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="confirmationCode" bssize="large">
        <Form.Label>Confirmation Code</Form.Label>
        <Form.Control
          autoFocus
          type="tel"
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
        />
        <Form.Text>Please check your email for the code.</Form.Text>
      </Form.Group>
      <LoaderButton
        block
        bssize="large"
        disabled={!validateForm()}
        type="submit"
        isLoading={loading}
        text="Verify"
        loadingText="Verifying…"
      />
    </Form>
  );
}
