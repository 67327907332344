import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { Auth, Hub } from "aws-amplify";
import Navigation from "./Navigation";

import "./WithUser.css"
import { Button } from "react-bootstrap";

const WithUser = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [currentSession, setCurrentSession] = useState(null);

  function userHasAuthenticated(authenticated, session) {
    setIsAuthenticated(authenticated);
    setIsAuthenticating(false);
    console.log("Setting current session to: ", session);
    setCurrentSession(session);
  }

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        console.log("currentSession is :", session);
        if (session) {
          userHasAuthenticated(true, session);
        } else {
          console.log("At mount time, couldn't get session!");
        }
      })
      .catch((e) => {
        console.log("currentSession error: ", e);
        userHasAuthenticated(false);
      });

    // let the Hub module listen on Auth events
    Hub.listen("auth", (data) => {
      console.log("Hub Auth Event: ", data);
      switch (data.payload.event) {
        case "signIn":
          Auth.currentSession()
            .then((session) => {
              userHasAuthenticated(true, session);
            })
            .catch((err) => console.log(err));
          break;
        case "signIn_failure":
          userHasAuthenticated(false, null);
          break;
        default:
          break;
      }
    });
  }, []);

  function renderAuthenticating() {
    console.log("Rendering authenticating");
    return <Loading />;
  }

  function renderUnauthenticated() {
    return (
      <div className="outer">
      <div className="inner">
        {console.log("Rendering unauth")}
        <div className="centerCell">
        <h1>Login to upload a new track.</h1>
        <div className="buttonBar">
        <Button href="/signup">Create Account</Button><Button href="/login">Sign In</Button>
        </div>
        </div>
      </div>
      </div>
    );
  }

  async function handleLogout(event) {
    await Auth.signOut();
    userHasAuthenticated(false);
  }

  return (
    <>
      <Navigation session={currentSession} onLogout={handleLogout} />
      {isAuthenticated ? props.children : isAuthenticating ? renderAuthenticating() : renderUnauthenticated()}
    </>
  );
};

export default WithUser;
