import React from "react";
import { Button } from "react-bootstrap";
import { Auth } from "aws-amplify";

import "./SignInWithFacebookButton.css";

export default function SignInWithFacebookButton(props) {
  const { verb } = props;
  return (
    <Button
      className="SignInWithFacebook"
      variant="primary"
      size="lg"
      onClick={() => Auth.federatedSignIn({ provider: "Facebook" })}
    >
      {verb ? verb : "Sign In"} with Facebook
    </Button>
  );
}
