import React from "react";
import { Button } from "react-bootstrap";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoteAvatar from "./NoteAvatar";
import NoteListDescriptionColumn from "./NoteListDescriptionColumn";

function NoteListRow(props) {
  const { mix, note, showAvatar, canEditNote, canDeleteNote } = props;

  function formattedLocationString() {
    let minutes = Math.trunc(note.locationSeconds / 60) + "";
    if (minutes.length < 2) minutes = "0" + minutes;
    let seconds = Math.trunc(note.locationSeconds % 60) + "";
    if (seconds.length < 2) seconds = "0" + seconds;

    return `${minutes}:${seconds}`;
  }

  // Checkbox

  function onDoneChanged(e) {
    if (props.onDoneChanged) props.onDoneChanged(note, e.target.checked);
  }

  function onDoneClicked(e) {
    e.stopPropagation();
  }

  // Row Click

  function onRowClicked(e) {
    if (props.onClick) props.onClick(note);
    e.stopPropagation();
  }

  // Buttons

  function onEditNote(e) {
    if (props.onEditNote) props.onEditNote(note);
    e.stopPropagation();
  }

  return (
    <div className="NoteListRow">
      <div className="checkbox">
        {!mix.originalID && (
          <input type="checkbox" onChange={(e) => onDoneChanged(e, note)} onClick={onDoneClicked} checked={note.done} />
        )}
      </div>
      <div className="avatar" onClick={(e) => onRowClicked(e, note)}>
        {showAvatar && <NoteAvatar note={note} />}
      </div>
      <div className="labels" onClick={(e) => onRowClicked(e, note)}>
        <div className="space"></div>
        <div className="location">{formattedLocationString()}</div>
        <NoteListDescriptionColumn note={note} />
        <div className="space"></div>
      </div>
      <div className="buttons">
        <div></div>
        {canEditNote || canDeleteNote ? (
          <Button className="EditNote SmallButton" variant="link" onClick={(e) => onEditNote(e, note)}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}

export default NoteListRow;
