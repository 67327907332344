import React from "react";
import { Button } from "react-bootstrap";
import { Auth } from "aws-amplify";

import "./SignInWithAppleButton.css";

export default function SignInWithAppleButton(props) {
  const { verb } = props;
  return (
    <Button
      className="SignInWithApple"
      variant="dark"
      size="lg"
      onClick={() => Auth.federatedSignIn({ provider: "SignInWithApple" })}
    >
      {verb ? verb : "Sign In"} with Apple
    </Button>
  );
}
