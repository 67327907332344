import React, { useState } from "react";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";

export default function SignUpAccount(props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0 && password === confirmPassword;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          given_name: firstName,
          family_name: lastName,
        },
      });

      if (props.onUserCreation) props.onUserCreation(newUser, email, password);
    } catch (e) {
      alert(e.message);
    }

    setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="email" bssize="large">
        {/* <Form.Label>Email</Form.Label> */}
        <Form.Control
          autoFocus
          type="email"
          autoComplete="username"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="first_name" bssize="large">
        {/* <Form.Label>First Name</Form.Label> */}
        <Form.Control
          value={firstName}
          autoComplete="given-name"
          placeholder="First Name"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="last_name" autoComplete="family-name" bssize="large">
        {/* <Form.Label>Last Name</Form.Label> */}
        <Form.Control
          value={lastName}
          placeholder="Last Name"
          autoComplete="family-name"
          onChange={(e) => setLastName(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="password" bssize="large">
        {/* <Form.Label>Password</Form.Label> */}
        <Form.Control
          autoComplete="new-password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
      </Form.Group>
      <Form.Group controlId="confirmPassword" bssize="large">
        {/* <Form.Label>Confirm Password</Form.Label> */}
        <Form.Control
          value={confirmPassword}
          autoComplete="new-password"
          placeholder="Confirm Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
        />
      </Form.Group>
      <LoaderButton
        block
        bsize="large"
        disabled={!validateForm()}
        type="submit"
        isLoading={loading}
        text="Create Account"
        loadingText="Creating New Account…"
      />
    </Form>
  );
}
