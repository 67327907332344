import React, { useState, useEffect, useRef } from "react";
import WithUser from "../components/WithUser";
import SplitView from "../components/SplitView";
import TrackList from "../components/TrackList";
import Track from "../components/Track";

import "./Home.css";
import { Overlay } from "react-bootstrap";

function Home(props) {
  const [selectedTrackID, setSelectedTrackID] = useState(null);
  const { redeemedTrack } = props;

  useEffect(() => {
    if (props.location.pathname.match(/\/track\/track:.+/)) {
      setSelectedTrackID(props.location.pathname.split("/").pop());
    }
  }, [props.location.pathname]);

  useEffect(() => {
    console.log("REDEEMED TRACK", redeemedTrack);
    if (redeemedTrack) {
      const trackID = redeemedTrack.id;
      props.history.replace("/track/" + trackID);
      setSelectedTrackID(trackID);
    }
  }, [redeemedTrack, props.history]);

  function onSelectTrack(trackID) {
    props.history.push("/track/" + trackID);
    setSelectedTrackID(trackID);
    setShowCollapsedLeftSplitViewPane(false);
  }

  function onSelectTrackToAvoidEmptySelection(trackID) {
    if (!showCollapsedLeftSplitViewPane) {
      onSelectTrack(trackID);
    }
  }

  function onTrackListClicked() {
    setSelectedTrackID(null);
    setShowCollapsedLeftSplitViewPane(true);
  }

  const [showCollapsedLeftSplitViewPane, setShowCollapsedLeftSplitViewPane] = useState(false);

  function renderTrackList() {
    return (
      <TrackList
        onSelectTrack={onSelectTrack}
        onSelectTrackToAvoidEmptySelection={onSelectTrackToAvoidEmptySelection}
        selectedTrackID={selectedTrackID}
      />
    );
  }

  function renderSplitView() {
    return (
      <SplitView showCollapsedLeft={showCollapsedLeftSplitViewPane}>
        {renderTrackList()}
        <Track trackID={selectedTrackID} onTrackListClicked={onTrackListClicked} />
      </SplitView>
    );
  }

  function renderWelcomeView() {
    return renderTrackList();
  }

  return (
    <div className="Home">
      {console.log("Home props: ", props)}
      <WithUser>{selectedTrackID ? renderSplitView() : renderWelcomeView()}</WithUser>
    </div>
  );
}

export default Home;
