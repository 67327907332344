import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import * as S3 from "aws-sdk/clients/s3";
import { Auth, API } from "aws-amplify";

import "./Player.css";

const Player = React.forwardRef((props, ref) => {
  const { mix } = props;
  const [url, setUrl] = useState(null);

  useEffect(() => {
    async function getURLForAudio() {
      if (mix) {
        if (mix.originalID) {
          // Shared mix, use MixDownload API
          const params = { body: { mixID: mix.id, trackID: mix.parentID } };
          const result = await API.post("mix", "/download", params);
          setUrl(result.url);
        } else {
          const credentials = await Auth.currentCredentials();
          const urlParams = {
            Bucket: mix.audioFile.bucket,
            Key: mix.audioFile.key,
            Expires: 3600,
          };
          const s3 = new S3({ region: mix.audioFile.region, credentials: credentials });
          setUrl(await s3.getSignedUrl("getObject", urlParams));
        }
      }
    }
    getURLForAudio();
  }, [mix]);

  function onProgress(progress) {
    if (props.onProgress) {
      props.onProgress(progress);
    }
  }

  return (
    <div>
      <div className="PlayerContainer">
        <ReactPlayer
          ref={ref}
          url={url}
          playing={false}
          controls={true}
          autoPlay={false}
          width="100%"
          height="30px"
          progressInterval={500}
          onProgress={onProgress}
        />
      </div>
    </div>
  );
});

export default Player;
