import React, { useState } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import TrackOpenNoteCount from "./TrackOpenNoteCount";
import SharedTrackAvatar from "./SharedTrackAvatar";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

function TrackListTableRow(props) {
  const { track, selected, upload } = props;

  function onEditClicked(e, track) {
    console.log("EDIT: ", track);
    setEditingTrackDescription(track.description);
    setIsEditing(true);
    e.stopPropagation();
  }

  function onDeleteClicked(e, track) {
    confirmAlert({
      title: "Delete Track",
      message: `Are you sure you want to delete "${track.description}"? This cannot be undone!`,
      buttons: [
        {
          label: "Delete",
          onClick: () => {
            if (props.onDeleteTrack) props.onDeleteTrack(track);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
    e.stopPropagation();
  }

  const [isEditing, setIsEditing] = useState(false);

  function onEditSaveClicked() {
    if (props.onUpdateTrack) props.onUpdateTrack({ ...track, description: editingTrackDescription });
    setIsEditing(false);
  }

  function onEditCancelClicked() {
    setIsEditing(false);
  }

  function onRowClicked(e) {
    if (props.onRowClicked && !isEditing) props.onRowClicked(track);
    e.stopPropagation();
  }

  const [editingTrackDescription, setEditingTrackDescription] = useState(track.description);

  function classNamePlusSelected(name) {
    return `${name} ${selected && "selected"}`;
  }

  function iconContent() {
    if (upload) {
      return (
        <div className={classNamePlusSelected("avatar uploadIcon")} onClick={onRowClicked}>
          {upload && <FontAwesomeIcon icon={faUpload} />}
        </div>
      );
    } else {
      return (
        <div className={classNamePlusSelected("avatar")} onClick={onRowClicked}>
          {track.originalID ? <SharedTrackAvatar track={track} /> : <TrackOpenNoteCount track={track} />}
        </div>
      );
    }
  }

  function labelsContent() {
    if (isEditing) {
      return (
        <div className={classNamePlusSelected("inlineEdit")} onClick={onRowClicked}>
          <input value={editingTrackDescription} onChange={(e) => setEditingTrackDescription(e.target.value)} />
          <Button size="sm" onClick={onEditCancelClicked}>
            Cancel
          </Button>
          <Button size="sm" onClick={onEditSaveClicked}>
            Save
          </Button>
        </div>
      );
    } else if (upload) {
      return (
        <div className={classNamePlusSelected("uploadProgress")} onClick={onRowClicked}>
          <div className="space"></div>
          <div className="track ellipsis">{track.description}</div>
          <ProgressBar now={upload.progress} label={`${upload.progress}%`} srOnly animated />
          <div className="space"></div>
        </div>
      );
    } else {
      return (
        <div className={classNamePlusSelected("labels-and-buttons")} onClick={onRowClicked}>
          <div className="labels" onClick={onRowClicked}>
            <div className="space"></div>
            <div className="track ellipsis">{track.description}</div>
            <div className="mix ellipsis head">{track.latestMix ? track.latestMix.description : ""}</div>
            <div className="space"></div>
          </div>
          {buttonsContent()}
        </div>
      );
    }
  }

  function buttonsContent() {
    if (!isEditing && !upload) {
      return (
        <div className={classNamePlusSelected("buttons")} onClick={onRowClicked}>
          {selected && (
            <React.Fragment>
              {track.originalID ? (
                <div />
              ) : (
                <Button
                  size="sm"
                  variant="outline-primary"
                  disabled={track.originalID != null}
                  onClick={(e) => onEditClicked(e, track)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
              <Button size="sm" variant="outline-danger" onClick={(e) => onDeleteClicked(e, track)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </React.Fragment>
          )}
        </div>
      );
    }
  }

  return (
    <div className="TrackListTableRow">
      {iconContent()}
      {labelsContent()}
    </div>
  );
}

export default TrackListTableRow;
