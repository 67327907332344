import React from "react";
import { openNotes } from "../graphql/queries";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

function TrackOpenNoteCount(props) {
  const noteCount = props.openNotes ? props.openNotes.noteCount : "";
  const styleClass = props.openNotes ? (props.openNotes.noteCount > 0 ? "NonZeroCount" : "ZeroCount") : "UnknownCount";
  return (
    <div className={`NoteCount ${styleClass}`}>
      <span>{noteCount}</span>
    </div>
  );
}

export default graphql(gql(openNotes), {
  options: (props) => ({
    fetchPolicy: "cache-and-network",
    variables: { trackID: props.track.id, mixID: props.track.latestMixID },
  }),
  props: (props) => ({
    openNotes: props.data.openNotes,
  }),
})(TrackOpenNoteCount);
