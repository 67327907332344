import React from "react";
import { Button } from "react-bootstrap";

import "./ShareSheetBody.css";

function ShareSheetBody(props) {
  const { shareURL } = props;
  return (
    <div className="ShareSheetBody">
      <div className="ShareText">
        <span>
          Sharing this Track with other people allows them to listen and add notes that you and all other listeners can
          see.
        </span>
      </div>
      <div className="ShareURL">
        <input value={shareURL} readOnly={true} />
        <Button onClick={() => navigator.clipboard.writeText(shareURL)}>Copy</Button>
      </div>
    </div>
  );
}

export default ShareSheetBody;
