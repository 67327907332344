import React from "react";
import { ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

function TrackListUploadTableRow(props) {
  const { upload } = props;
  return (
    <div className="TrackListTableRow">
      <div className="avatar uploadIcon">
        <FontAwesomeIcon icon={faUpload} />
      </div>
      <div className="uploadProgress">
        <div className="space"></div>
        <div className="track ellipsis">{upload.name}</div>
        <div />
        <div className="space"></div>
      </div>
    </div>
  );
}

export default TrackListUploadTableRow;
