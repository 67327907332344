import React, { useEffect, useState } from "react";
import { onDeltaTrack } from "../graphql/subscriptions";
import { listTracks } from "../graphql/queries";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import { Auth } from "aws-amplify";
import TrackListTableRow from "./TrackListTableRow";
import TrackListUploadTableRow from "./TrackListUploadTableRow";

import "./TrackList.css";
import { updateTrack, deleteTrack } from "../graphql/mutations";
import MixUploadDropZone from "./MixUploadDropZone";

function TrackList(props) {
  const { tracks, onSelectTrack, onSelectTrackToAvoidEmptySelection, selectedTrackID } = props;

  useEffect(() => {
    if (props.subscribeToNewTracks) {
      props.subscribeToNewTracks();
    }
  }, []);

  useEffect(() => {
    if (!selectedTrackID && tracks && tracks.length > 0) {
      if (onSelectTrackToAvoidEmptySelection) {
        onSelectTrackToAvoidEmptySelection(tracks[0].id);
      }
    }
    // Don't try and force a selection even if selectedTrackID
    // exists but isn't found in the track list. The track list
    // will first be loaded from cache and so the track might
    // be valid but not yet in our track list (e.g. if just redeemed)
  }, []);

  function onRowClicked(track) {
    if (onSelectTrack) {
      onSelectTrack(track.id);
    }
  }

  function onDeleteTrack(track) {
    props.deleteTrack(track);
  }

  function onUpdateTrack(track) {
    props.updateTrack(track);
  }

  const [uploads, setUploads] = useState([]);

  function onUploadProgress(uploads) {
    setUploads(uploads);
  }

  return (
    <div className={tracks.length ? "TrackList" : "EmptyTrackList"}>
      <MixUploadDropZone onUploadProgress={onUploadProgress}>
        <div className="TrackListGrid">
          {uploads
            .filter((upload) => !upload.trackID)
            .map((upload) => (
              <TrackListUploadTableRow key={upload.id} upload={upload} />
            ))}
          {tracks.map((track) => (
            <TrackListTableRow
              key={track.id}
              track={track}
              selected={track.id === selectedTrackID}
              upload={uploads.find((upload) => upload.trackID === track.id)}
              onRowClicked={onRowClicked}
              onDeleteTrack={onDeleteTrack}
              onUpdateTrack={onUpdateTrack}
            />
          ))}
        </div>
      </MixUploadDropZone>
    </div>
  );
}

export default compose(
  graphql(gql(listTracks), {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
    }),
    props: (props) => {
      const tracks = props.data.listTracks ? props.data.listTracks.items : [];
      const sortedTracks = [...tracks].sort((a, b) => b.timestamp - a.timestamp);
      return {
        tracks: sortedTracks,
        subscribeToNewTracks: async () => {
          const session = await Auth.currentSession();
          const userID = session.getIdToken().payload.sub;
          props.data.subscribeToMore({
            document: gql(onDeltaTrack),
            variables: {
              userID: userID,
            },
            updateQuery: (
              prev,
              {
                subscriptionData: {
                  data: { onDeltaTrack },
                },
              }
            ) => {
              console.log("onDeltaTrack: ", onDeltaTrack);
              const updatedItems = prev.listTracks.items.filter((track) => track.id !== onDeltaTrack.id);
              if (onDeltaTrack.aws_ds !== "DELETE") {
                console.log("HANDLING UPDATE/ADD: ", onDeltaTrack);
                updatedItems.push(onDeltaTrack);
              } else {
                console.log("HANDLING DELETE", onDeltaTrack);
              }
              return {
                ...prev,
                listTracks: {
                  __typename: "TrackConnection",
                  items: updatedItems,
                  nextToken: null,
                },
              };
            },
          });
        },
      };
    },
  }),
  graphql(gql(updateTrack), {
    options: (props) => ({
      update: (dataProxy, { data: { updateTrack } }) => {
        const query = gql(listTracks);
        const queryOptions = { query };
        const data = dataProxy.readQuery(queryOptions);
        const index = data.listTracks.items.findIndex((track) => {
          return track.id === updateTrack.id;
        });
        if (index !== -1) {
          data.listTracks.items[index].description = updateTrack.description;
        }
        dataProxy.writeQuery({ query, data });
      },
    }),
    props: (props) => ({
      updateTrack: (update) => {
        const input = {
          id: update.id,
          description: update.description,
        };
        props.mutate({
          variables: { input },
          optimisticResponse: () => ({
            updateTrack: { ...update, __typename: "Track" },
          }),
        });
      },
    }),
  }),
  graphql(gql(deleteTrack), {
    options: (props) => ({
      update: (dataProxy, { data: { deleteTrack } }) => {
        const query = gql(listTracks);
        const queryOptions = { query };
        const data = dataProxy.readQuery(queryOptions);
        data.listTracks.items = data.listTracks.items.filter((track) => track.id !== deleteTrack.id);
        dataProxy.writeQuery({ query, data });
      },
    }),
    props: (props) => ({
      deleteTrack: (track) => {
        const input = {
          id: track.id,
        };
        props.mutate({
          variables: { input },
          optimisticResponse: () => ({
            deleteTrack: { ...track, __typename: "Track" },
          }),
        });
      },
    }),
  })
)(TrackList);
