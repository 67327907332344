import React from "react";
import "./SharedTrackLanding.css";

function SharedTrackLanding(props) {
  const url = window.location.href;
  const shareToken = url.substring(url.lastIndexOf("/") + 1);
  const appShareURL = `tracknotes://sharedtrack/t/${shareToken}`;
  return (
    <div>
      <div className="SharedTrackLanding">
        <h1>Install the TrackNotes app</h1>
        <p>To listen to this track you need the TrackNotes app on iPhone or iPad.</p>
        <p>
          <b>Already got the app? </b>
          <br />
          Click here <a href={appShareURL}>to open in TrackNotes</a>
        </p>
        <p>
          <b>Want in on the beta test of TrackNotes?</b>
          <br />
          Click here to <a href={"https://testflight.apple.com/join/8kULq06b"}>join the test community</a>.
        </p>
        <p>
          <b>Or, continue on the web...</b>
          <br />
          Click here to <a href={`/redeem/${shareToken}`}>listen to this track in your browser</a>.
        </p>
      </div>
    </div>
  );
}

export default SharedTrackLanding;
