import React, { useState } from "react";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";

import "./LoginPane.css";
import SignInWithFacebookButton from "./SignInWithFacebookButton";
import SignInWithAppleButton from "./SignInWithAppleButton";

function LoginPane(props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);

    try {
      await Auth.signIn(email, password);
      // let session = await Auth.currentSession();
      // setState({ currentSession: session });
      // props.userHasAuthenticated(true, session);
      props.history.push("/");
    } catch (e) {
      alert(e.message);
      setLoading(false);
    }
  }

  return (
    <div className="LoginPane">
      <h1>Sign In</h1>
      <SignInWithAppleButton verb="Continue" />
      <SignInWithFacebookButton verb="Continue" />
      <Form onSubmit={handleSubmit}>
        <div className="Separator">or</div>
        <Form.Group controlId="email" bssize="large">
          <Form.Control
            autoFocus
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="password" bssize="large">
          <Form.Control
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </Form.Group>
        <LoaderButton
          block
          bssize="large"
          disabled={!validateForm()}
          type="submit"
          isLoading={loading}
          text="Login"
          loadingText="Logging in…"
        />
      </Form>
    </div>
  );
}

export default LoginPane;
