import React, { useState } from "react";
import { Button } from "react-bootstrap";

import "./NoteListInlineEditorRow.css";

function NoteListInlineEditorRow(props) {
  const { note, showDeleteButton, showEditButton } = props;
  const [description, setDescription] = useState(note.description);

  function onSaveClicked(e) {
    if (props.onSave) props.onSave({ ...note, description });
    e.stopPropagation();
  }

  function onCancelClicked(e) {
    if (props.onCancel) props.onCancel(note);
    e.stopPropagation();
  }

  function onDeleteClicked(e) {
    if (props.onDelete) props.onDelete(note);
    e.stopPropagation();
  }

  function handleDescriptionKeyDown(e) {
    if (e.key === "Enter") {
      onSaveClicked(e);
    }
  }

  return (
    <div className="NoteListInlineEditorRow">
      {note.noteType === 0 && (
        <input
          className="NoteTextEntry"
          key={note.id}
          value={description}
          autoFocus
          disabled={!showEditButton}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          onKeyDown={handleDescriptionKeyDown}
        />
      )}
      <div className="Buttons">
        {showDeleteButton ? (
          <Button className="delete" variant="danger" onClick={onDeleteClicked} disabled={!showDeleteButton}>
            Delete
          </Button>
        ) : (
          <div />
        )}
        <div className="space"></div>
        <Button className="cancel" onClick={onCancelClicked}>
          Cancel
        </Button>
        {showEditButton ? (
          <Button className="save" onClick={onSaveClicked} disabled={!showEditButton}>
            Save
          </Button>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}

export default NoteListInlineEditorRow;
