import React from "react";
import Nav from "react-bootstrap/Nav";
import { Navbar } from "react-bootstrap";

import "./Navigation.css";

function Navigation(props) {
  const { session, onLogout } = props;

  function unauthenticatedNav() {
    return (
      <Nav>
        <Nav.Link href="/signup">Create Account</Nav.Link>
        <Nav.Link href="/login">Sign In</Nav.Link>
      </Nav>
    );
  }

  function authenticatedNav() {
    const { email } = session.idToken.payload;
    return (
      <Nav>
        <Navbar.Text>
          Signed in as: <a href="/account">{email}</a>
        </Navbar.Text>
        <Nav.Link onClick={onLogout}>Logout</Nav.Link>
      </Nav>
    );
  }

  return (
    <Navbar className="Navigation" collapseOnSelect expand="lg">
      <Navbar.Brand href="/">
        <img src="/logo.png" width="40" height="40" className="logo-image d-inline-block align-top" alt="TrackNotes" />
        <span>Track Notes</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">{/* <Nav.Link href="/tracks">My Tracks</Nav.Link> */}</Nav>
        {session ? authenticatedNav() : unauthenticatedNav()}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
