/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deltaNotes = `query DeltaNotes(
  $filter: TableNoteFilterInput
  $limit: Int
  $nextToken: String
  $parentID: ID!
) {
  deltaNotes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    parentID: $parentID
  ) {
    items {
      aws_ds
      description
      done
      hidden
      id
      locationSeconds
      noteType
      parentID
      source
      timestamp
      userID
    }
    nextToken
  }
}
`;
export const deltaTracks = `query DeltaTracks(
  $filter: TableTrackFilterInput
  $limit: Int
  $nextToken: String
) {
  deltaTracks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      aws_ds
      description
      id
      latestMix {
        audioFile {
          bucket
          key
          region
        }
        audioStatus
        aws_ds
        description
        deviceID
        id
        originalID
        otherUserID
        parentID
        source
        timestamp
        userID
      }
      latestMixID
      message
      originalID
      otherUserID
      parentID
      placeholderID
      timestamp
      userID
    }
    nextToken
  }
}
`;
export const getMix = `query GetMix($id: ID!) {
  getMix(id: $id) {
    audioFile {
      bucket
      key
      region
    }
    audioStatus
    aws_ds
    description
    deviceID
    id
    originalID
    otherUserID
    parentID
    source
    timestamp
    userID
  }
}
`;
export const getNote = `query GetNote($id: ID!) {
  getNote(id: $id) {
    aws_ds
    description
    done
    hidden
    id
    locationSeconds
    noteType
    parentID
    source
    timestamp
    userID
  }
}
`;
export const getTrack = `query GetTrack($id: ID!) {
  getTrack(id: $id) {
    aws_ds
    description
    id
    latestMix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    latestMixID
    message
    originalID
    otherUserID
    parentID
    placeholderID
    timestamp
    userID
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    avatarURL
    displayName
  }
}
`;
export const listFriends = `query ListFriends(
  $filter: TableFriendFilterInput
  $limit: Int
  $nextToken: String
) {
  listFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      aws_ds
      contact
      contactType
      id
      initiatedByUserID
      otherUserID
      status
      userID
    }
    nextToken
  }
}
`;
export const listMixes = `query ListMixes(
  $filter: TableMixFilterInput
  $limit: Int
  $nextToken: String
  $parentID: ID!
) {
  listMixes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    parentID: $parentID
  ) {
    items {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    nextToken
  }
}
`;
export const listNotes = `query ListNotes(
  $filter: TableNoteFilterInput
  $limit: Int
  $nextToken: String
  $parentID: ID!
) {
  listNotes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    parentID: $parentID
  ) {
    items {
      aws_ds
      description
      done
      hidden
      id
      locationSeconds
      noteType
      parentID
      source
      timestamp
      userID
    }
    nextToken
  }
}
`;
export const listTracks = `query ListTracks(
  $filter: TableTrackFilterInput
  $limit: Int
  $nextToken: String
) {
  listTracks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      aws_ds
      description
      id
      latestMix {
        audioFile {
          bucket
          key
          region
        }
        audioStatus
        aws_ds
        description
        deviceID
        id
        originalID
        otherUserID
        parentID
        source
        timestamp
        userID
      }
      latestMixID
      message
      originalID
      otherUserID
      parentID
      placeholderID
      timestamp
      userID
    }
    nextToken
  }
}
`;
export const openNotes = `query OpenNotes($mixID: ID, $trackID: ID!) {
  openNotes(mixID: $mixID, trackID: $trackID) {
    noteCount
  }
}
`;
export const trackShareUrlGrant = `query TrackShareUrlGrant($mixID: ID!) {
  trackShareURLGrant(mixID: $mixID) {
    url
  }
}
`;
export const trackShareUrlRedeem = `query TrackShareUrlRedeem($url: String!) {
  trackShareURLRedeem(url: $url) {
    mix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    ownTrack {
      aws_ds
      description
      id
      latestMix {
        audioFile {
          bucket
          key
          region
        }
        audioStatus
        aws_ds
        description
        deviceID
        id
        originalID
        otherUserID
        parentID
        source
        timestamp
        userID
      }
      latestMixID
      message
      originalID
      otherUserID
      parentID
      placeholderID
      timestamp
      userID
    }
    sharedTrack {
      aws_ds
      description
      id
      latestMix {
        audioFile {
          bucket
          key
          region
        }
        audioStatus
        aws_ds
        description
        deviceID
        id
        originalID
        otherUserID
        parentID
        source
        timestamp
        userID
      }
      latestMixID
      message
      originalID
      otherUserID
      parentID
      placeholderID
      timestamp
      userID
    }
  }
}
`;
