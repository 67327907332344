import React, { useEffect, useState } from "react";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import Loading from "./Loading";
import { listNotes } from "../graphql/queries";
import { updateNote, createNote, deleteNote } from "../graphql/mutations";
import { onDeltaMixNote } from "../graphql/subscriptions";
import NoteListInlineEditorRow from "./NoteListInlineEditorRow";

import "./NoteList.css";
import { confirmAlert } from "react-confirm-alert";
import NoteListRow from "./NoteListRow";
import NoteListAddNoteControls from "./NoteListAddNoteControls";

const NoteList = (props) => {
  const { mix, locationSeconds, notes } = props;

  const [openNotes, setOpenNotes] = useState([]);
  const [doneNotes, setDoneNotes] = useState([]);
  const [hasNotesFromOtherUsers, setHasNotesFromOtherUsers] = useState();

  useEffect(() => {
    const sortedNotes = [...notes].sort((a, b) => {
      if (a.locationSeconds === b.locationSeconds) {
        return a.timestamp < b.timestamp;
      } else {
        return a.locationSeconds > b.locationSeconds;
      }
    });
    setHasNotesFromOtherUsers(
      sortedNotes.findIndex((note) => {
        return note.userID !== mix.userID;
      }) !== -1
    );
    setOpenNotes(
      sortedNotes.filter((item) => {
        return !item.done;
      })
    );
    setDoneNotes(
      sortedNotes.filter((item) => {
        return item.done;
      })
    );
  }, [notes, mix]);

  useEffect(() => {
    if (props.subscribeToNewNotes) {
      props.subscribeToNewNotes();
    }
  }, []);

  function onAddNote(note) {
    props.createNote(note);
  }

  function onRowClicked(note) {
    console.log("NoteList got click");
    if (props.onNoteSelected) props.onNoteSelected(note);
  }

  function onDoneChanged(note, checked) {
    props.updateNote({ ...note, done: checked });
  }

  const [noteBeingEdited, setNoteBeingEdited] = useState(null);

  function onEditNote(note) {
    setNoteBeingEdited(note);
  }

  function onEditNoteSave(note) {
    props.updateNote(note);
    setNoteBeingEdited(null);
  }

  function onEditNoteCancel(note) {
    setNoteBeingEdited(null);
  }

  function onDeleteNote(note) {
    const alertMessage =
      note.noteType === 0
        ? `Are you sure you want to delete the note "${note.description}"? This cannot be undone!`
        : `Are you sure you want to delete this note? This cannot be undone!`;
    confirmAlert({
      title: "Delete Note",
      message: alertMessage,
      buttons: [
        {
          label: "Delete",
          onClick: () => {
            props.deleteNote(note);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  }

  function canDeleteNote(note) {
    return !mix.originalID || note.userID === mix.userID;
  }

  function canEditNote(note) {
    return canDeleteNote(note) && note.noteType === 0;
  }

  function renderNotesList() {
    return (
      <div className="NoteList">
        <NoteListAddNoteControls mix={mix} locationSeconds={locationSeconds} onAddNote={onAddNote} />
        <div className="NoteListGrid OpenNotes">
          {openNotes.map((note) => {
            if (noteBeingEdited && noteBeingEdited.id === note.id) {
              return (
                <NoteListInlineEditorRow
                  key={noteBeingEdited.id}
                  note={noteBeingEdited}
                  onSave={onEditNoteSave}
                  onCancel={onEditNoteCancel}
                  onDelete={onDeleteNote}
                  showDeleteButton={canDeleteNote(note)}
                  showEditButton={canEditNote(note)}
                />
              );
            } else {
              return (
                <NoteListRow
                  key={note.id}
                  mix={mix}
                  note={note}
                  showAvatar={hasNotesFromOtherUsers}
                  canDeleteNote={canDeleteNote(note)}
                  canEditNote={canEditNote(note)}
                  onDoneChanged={onDoneChanged}
                  onClick={onRowClicked}
                  onDeleteNote={onDeleteNote}
                  onEditNote={onEditNote}
                />
              );
            }
          })}
        </div>
        {doneNotes.length > 0 && (
          <div className="NoteListGrid DoneNotes">
            {doneNotes.map((note) => (
              <NoteListRow
                key={note.id}
                mix={mix}
                note={note}
                showAvatar={hasNotesFromOtherUsers}
                onDoneChanged={onDoneChanged}
                onClick={onRowClicked}
                onDeleteNote={onDeleteNote}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return <div>{props.notes ? renderNotesList() : <Loading />}</div>;
};

export default compose(
  graphql(gql(listNotes), {
    options: (props) => {
      const parentID = props.mix.originalID ? props.mix.originalID : props.mix.id;
      return {
        variables: { parentID },
        fetchPolicy: "cache-and-network",
      };
    },
    props: (props) => {
      console.log("BaseQuery props: ", props);
      return {
        notes: props.data.listNotes ? props.data.listNotes.items : [],
        subscribeToNewNotes: () => {
          const parentID = props.ownProps.mix.originalID ? props.ownProps.mix.originalID : props.ownProps.mix.id;
          props.data.subscribeToMore({
            document: gql(onDeltaMixNote),
            variables: { parentID },
            updateQuery: (
              prev,
              {
                subscriptionData: {
                  data: { onDeltaMixNote },
                },
              }
            ) => {
              console.log("onDeltaMixNote added: ", onDeltaMixNote);
              const updatedItems = prev.listNotes.items.filter((note) => note.id !== onDeltaMixNote.id);
              if (onDeltaMixNote.aws_ds !== "DELETE") {
                console.log("HANDLING NOTE UPDATE/ADD: ", onDeltaMixNote);
                updatedItems.push(onDeltaMixNote);
              } else {
                console.log("HANDLING NOTE DELETE: ", onDeltaMixNote);
              }
              return {
                ...prev,
                listNotes: {
                  __typename: "TrackConnection",
                  items: updatedItems,
                  nextToken: null,
                },
              };
            },
          });
        },
      };
    },
  }),
  graphql(gql(updateNote), {
    options: (props) => ({
      update: (dataProxy, { data: { updateNote } }) => {
        const query = gql(listNotes);
        const parentID = props.mix.originalID ? props.mix.originalID : props.mix.id;
        const queryOptions = { query, variables: { parentID } };
        const data = dataProxy.readQuery(queryOptions);
        const index = data.listNotes.items.findIndex((note) => {
          return note.id === updateNote.id;
        });
        if (index !== -1) {
          data.listNotes.items[index].done = updateNote.done;
        }
        dataProxy.writeQuery({ query, data, variables: { parentID } });
      },
    }),
    props: (props) => ({
      updateNote: (update) => {
        const input = {
          id: update.id,
          done: update.done,
          description: update.description,
        };
        props.mutate({
          variables: { input },
          optimisticResponse: () => ({
            updateNote: { ...update, __typename: "Note" },
          }),
        });
      },
    }),
  }),
  graphql(gql(createNote), {
    options: (props) => ({
      update: (dataProxy, { data: { createNote } }) => {
        const query = gql(listNotes);
        const parentID = props.mix.originalID ? props.mix.originalID : props.mix.id;
        const queryOptions = { query, variables: { parentID } };
        const data = dataProxy.readQuery(queryOptions);
        data.listNotes.items = [...data.listNotes.items.filter((note) => note.id !== createNote.id), createNote];
        dataProxy.writeQuery({ query, data: data, variables: { parentID } });
      },
    }),
    props: (props) => ({
      createNote: (createNote) => {
        const createNoteInput = {
          description: createNote.description,
          locationSeconds: createNote.locationSeconds,
          noteType: createNote.noteType, // 0=Text, 1=Voice, 2=ThumbsUp, 3=ThumbsDown
          parentID: props.ownProps.mix.id, // The createNote lambda sorts out original ownership
          source: 1, // UserEntered
        };
        props.mutate({
          variables: { input: createNoteInput },
          optimisticResponse: () => ({
            createNote: {
              ...createNote,
              aws_ds: null,
              description: createNote.description ? createNote.description : null,
              __typename: "Note",
            },
          }),
        });
      },
    }),
  }),
  graphql(gql(deleteNote), {
    options: (props) => ({
      update: (dataProxy, { data: { deleteNote } }) => {
        const query = gql(listNotes);
        const parentID = props.mix.originalID ? props.mix.originalID : props.mix.id;
        const queryOptions = { query, variables: { parentID } };
        const data = dataProxy.readQuery(queryOptions);
        data.listNotes.items = data.listNotes.items.filter((note) => note.id !== deleteNote.id);
        dataProxy.writeQuery({ query, data: data, variables: { parentID } });
      },
    }),
    props: (props) => ({
      deleteNote: (deleteNote) => {
        const deleteNoteInput = {
          id: deleteNote.id,
        };
        props.mutate({
          variables: { input: deleteNoteInput },
          optimisticResponse: () => ({
            deleteNote: { ...deleteNote, aws_ds: null, __typename: "Note" },
          }),
        });
      },
    }),
  })
)(NoteList);
