import React from "react";
import ReactDOM from "react-dom";
import Amplify, { Auth } from "aws-amplify";
import App from "./App";
import config from "./config";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.userPool.userPoolRegion,
    userPoolId: config.userPool.userPoolID,
    identityPoolId: config.identityPool.identityPoolID,
    userPoolWebClientId: config.userPool.webAppID,
  },
  API: {
    endpoints: [
      {
        name: "mix",
        endpoint: config.apiGateway.url,
        region: config.apiGateway.region,
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },
    ],
  },
  aws_project_region: config.appSync.region,
  aws_appsync_graphqlEndpoint: config.appSync.url,
  aws_appsync_region: config.appSync.region,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
});

const oauth = {
  domain: config.webOAuth.cognitoAuthDomain,
  scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
  redirectSignIn: config.webOAuth.redirectSignIn,
  redirectSignOut: config.webOAuth.redirectSignOut,
  responseType: "code",
};

Auth.configure({ oauth });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
