import React from "react";
import { getUser } from "../graphql/queries";
import Avatar from "react-avatar";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

function NoteAvatar(props) {
  const { user } = props;
  return <Avatar name={user ? user.displayName : "Unknown User"} round size={45} />;
}

export default graphql(gql(getUser), {
  options: (props) => ({
    fetchPolicy: "cache-and-network",
    variables: { id: props.note.userID },
  }),
  props: (props) => ({
    user: props.data.getUser,
  }),
})(NoteAvatar);
