import React from "react";
import Navigation from "../components/Navigation";
import SignUpPane from "../components/SignUpPane";

import "./Signup.css";

export default function SignUp(props) {
  return (
    <div className="SignUp">
      <Navigation />
      <SignUpPane history={props.history} />
    </div>
  );
}
