import React, { useEffect, useState } from "react";
import WithUser from "../components/WithUser";
import Loading from "../components/Loading";
import { API, graphqlOperation } from "aws-amplify";
import { trackShareUrlRedeem } from "../graphql/queries";
import Home from "./Home";

function SharedTrackRedeem(props) {
  const [redeemedTrack, setRedeemedTrack] = useState(null);
  const url = window.location.href;
  const shareURL = url.replace("/redeem/", "/t/");
  console.log("URL: ", shareURL);
  console.log("Redeem Props: ", props);

  useEffect(() => {
    async function redeemURL() {
      const redeemData = await API.graphql(graphqlOperation(trackShareUrlRedeem, { url: shareURL }));
      console.log("redeemData: ", redeemData);
      if (redeemData.data.trackShareURLRedeem.ownTrack) {
        // We've tried to redeem our own track!
        setRedeemedTrack(redeemData.data.trackShareURLRedeem.ownTrack);
      } else if (redeemData.data.trackShareURLRedeem.sharedTrack) {
        // We've redeemed a shared track
        console.log("Setting redeemed track to: ", redeemData.data.trackShareURLRedeem.sharedTrack);
        setRedeemedTrack(redeemData.data.trackShareURLRedeem.sharedTrack);
      }
    }
    if (!redeemedTrack) {
      console.log("REDEEMING");
      redeemURL();
    }
    return () => {};
  }, []);

  return redeemedTrack ? (
    <Home {...props} redeemedTrack={redeemedTrack} />
  ) : (
    <WithUser>
      <Loading />
    </WithUser>
  );
}

export default SharedTrackRedeem;
