import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Loading from "./Loading";
import ShareSheetBody from "./ShareSheetBody";
import { API, graphqlOperation } from "aws-amplify";
import { trackShareUrlGrant } from "../graphql/queries";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import "./ShareSheet.css";

function ShareSheet(props) {
  const { show, track, mix } = props;

  function handleClose(e) {
    if (props.onClose) props.onClose();
  }

  const [shareURL, setShareURL] = useState();

  useEffect(() => {
    async function fetchShareURL() {
      const shareData = await API.graphql(graphqlOperation(trackShareUrlGrant, { mixID: mix.id }));
      setShareURL(shareData.data.trackShareURLGrant.url);
    }
    if (show) {
      fetchShareURL();
    }
    return () => {};
  }, [show, mix]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      {console.log("show: ", { show })}
      <Modal.Header closeButton>
        <Modal.Title>Share "{track.description}"</Modal.Title>
      </Modal.Header>
      <Modal.Body>{shareURL ? <ShareSheetBody shareURL={shareURL} /> : <Loading />}</Modal.Body>
      <Modal.Footer>
        <div className="ShareLinks">
          {shareURL && (
            <>
              <FacebookShareButton url={shareURL}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <TwitterShareButton url={shareURL}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <EmailShareButton url={shareURL}>
                <EmailIcon size={32} round={true} />
              </EmailShareButton>
            </>
          )}
        </div>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ShareSheet;
