import React, { useState } from "react";
import UUID from "uuid/v4";
import { Button } from "react-bootstrap";
import { faPlus, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./NoteListAddNoteControls.css";

function NoteListAddNoteControls(props) {
  const { mix, locationSeconds } = props;

  function formattedLocationString() {
    let minutes = Math.trunc(locationSeconds / 60) + "";
    if (minutes.length < 2) minutes = "0" + minutes;
    let seconds = Math.trunc(locationSeconds % 60) + "";
    if (seconds.length < 2) seconds = "0" + seconds;

    return `${minutes}:${seconds}`;
  }

  const [newNoteDescription, setNewNoteDescription] = useState("");

  function newNoteWithProperties(children) {
    const note = {
      id: UUID(),
      locationSeconds: locationSeconds,
      noteType: 0,
      userID: mix.userID,
      done: false,
      hidden: false,
      parentID: mix.id,
      timestamp: Math.round(new Date().getTime() / 1000),
      source: 1,
    };
    return { ...note, ...children };
  }

  function onAddNote(e) {
    if (newNoteDescription && newNoteDescription.length > 0) {
      const note = newNoteWithProperties({ description: newNoteDescription });
      if (props.onAddNote) props.onAddNote(note);
      setNewNoteDescription("");
    }
    e.stopPropagation();
  }

  function addThumbsUpOrDownNote(isThumbsUp) {
    const note = newNoteWithProperties({ noteType: isThumbsUp ? 2 : 3 });
    if (props.onAddNote) props.onAddNote(note);
  }

  function onAddThumbsUpNote(e) {
    addThumbsUpOrDownNote(true);
    e.stopPropagation();
  }

  function onAddThumbsDownNote(e) {
    addThumbsUpOrDownNote(false);
    e.stopPropagation();
  }

  function handleNewNoteDescriptionKeyDown(e) {
    if (e.key === "Enter") {
      onAddNote(e);
    }
  }

  return (
    <div className="NoteListAddNoteControls">
      <div className="timestamp">{formattedLocationString()}</div>
      <div className="text-input">
        <input
          className="NewNoteTextField"
          onChange={(e) => setNewNoteDescription(e.target.value)}
          onKeyDown={handleNewNoteDescriptionKeyDown}
          placeholder="Add new note..."
          value={newNoteDescription}
        />
        <div className="add-button">
          <Button className="AddNote" onClick={(e) => onAddNote(e)} disabled={newNoteDescription.length === 0}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      </div>
      <div className="thumb-buttons">
        <Button className="ThumbsUp" onClick={(e) => onAddThumbsUpNote(e)}>
          <FontAwesomeIcon icon={faThumbsUp} />
        </Button>{" "}
        <Button className="ThumbsDown" onClick={(e) => onAddThumbsDownNote(e)}>
          <FontAwesomeIcon icon={faThumbsDown} />
        </Button>
      </div>
    </div>
  );
}

export default NoteListAddNoteControls;
