/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const blockFriend = `mutation BlockFriend($input: FriendIDInput!) {
  blockFriend(input: $input) {
    aws_ds
    contact
    contactType
    id
    initiatedByUserID
    otherUserID
    status
    userID
  }
}
`;
export const createNote = `mutation CreateNote($input: CreateNoteInput!) {
  createNote(input: $input) {
    aws_ds
    description
    done
    hidden
    id
    locationSeconds
    noteType
    parentID
    source
    timestamp
    userID
  }
}
`;
export const deleteFriend = `mutation DeleteFriend($input: FriendIDInput!) {
  deleteFriend(input: $input) {
    aws_ds
    contact
    contactType
    id
    initiatedByUserID
    otherUserID
    status
    userID
  }
}
`;
export const deleteMix = `mutation DeleteMix($input: DeleteMixInput!) {
  deleteMix(input: $input) {
    audioFile {
      bucket
      key
      region
    }
    audioStatus
    aws_ds
    description
    deviceID
    id
    originalID
    otherUserID
    parentID
    source
    timestamp
    userID
  }
}
`;
export const deleteNote = `mutation DeleteNote($input: DeleteNoteInput!) {
  deleteNote(input: $input) {
    aws_ds
    description
    done
    hidden
    id
    locationSeconds
    noteType
    parentID
    source
    timestamp
    userID
  }
}
`;
export const deleteTrack = `mutation DeleteTrack($input: DeleteTrackInput!) {
  deleteTrack(input: $input) {
    aws_ds
    description
    id
    latestMix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    latestMixID
    message
    originalID
    otherUserID
    parentID
    placeholderID
    timestamp
    userID
  }
}
`;
export const hideNote = `mutation HideNote($input: HideNoteInput!) {
  hideNote(input: $input) {
    aws_ds
    description
    done
    hidden
    id
    locationSeconds
    noteType
    parentID
    source
    timestamp
    userID
  }
}
`;
export const internalAddFriend = `mutation InternalAddFriend($input: InternalAddFriendInput!) {
  internalAddFriend(input: $input) {
    aws_ds
    contact
    contactType
    id
    initiatedByUserID
    otherUserID
    status
    userID
  }
}
`;
export const internalCreateMix = `mutation InternalCreateMix($input: InternalCreateMixInput!) {
  internalCreateMix(input: $input) {
    audioFile {
      bucket
      key
      region
    }
    audioStatus
    aws_ds
    description
    deviceID
    id
    originalID
    otherUserID
    parentID
    source
    timestamp
    userID
  }
}
`;
export const internalCreateTrack = `mutation InternalCreateTrack($input: InternalCreateTrackInput!) {
  internalCreateTrack(input: $input) {
    aws_ds
    description
    id
    latestMix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    latestMixID
    message
    originalID
    otherUserID
    parentID
    placeholderID
    timestamp
    userID
  }
}
`;
export const internalNotifyTrackChange = `mutation InternalNotifyTrackChange($input: InternalNotifyTrackChangeInput!) {
  internalNotifyTrackChange(input: $input) {
    aws_ds
    description
    id
    latestMix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    latestMixID
    message
    originalID
    otherUserID
    parentID
    placeholderID
    timestamp
    userID
  }
}
`;
export const internalUpdateFriend = `mutation InternalUpdateFriend($input: InternalUpdateFriendInput!) {
  internalUpdateFriend(input: $input) {
    aws_ds
    contact
    contactType
    id
    initiatedByUserID
    otherUserID
    status
    userID
  }
}
`;
export const internalUpdateMixAudio = `mutation InternalUpdateMixAudio($input: InternalUpdateMixAudioInput!) {
  internalUpdateMixAudio(input: $input) {
    audioFile {
      bucket
      key
      region
    }
    audioStatus
    aws_ds
    description
    deviceID
    id
    originalID
    otherUserID
    parentID
    source
    timestamp
    userID
  }
}
`;
export const internalUpdateTrack = `mutation InternalUpdateTrack($input: InternalUpdateTrackInput!) {
  internalUpdateTrack(input: $input) {
    aws_ds
    description
    id
    latestMix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    latestMixID
    message
    originalID
    otherUserID
    parentID
    placeholderID
    timestamp
    userID
  }
}
`;
export const unblockFriend = `mutation UnblockFriend($input: FriendIDInput!) {
  unblockFriend(input: $input) {
    aws_ds
    contact
    contactType
    id
    initiatedByUserID
    otherUserID
    status
    userID
  }
}
`;
export const updateMix = `mutation UpdateMix($input: UpdateMixInput!) {
  updateMix(input: $input) {
    audioFile {
      bucket
      key
      region
    }
    audioStatus
    aws_ds
    description
    deviceID
    id
    originalID
    otherUserID
    parentID
    source
    timestamp
    userID
  }
}
`;
export const updateNote = `mutation UpdateNote($input: UpdateNoteInput!) {
  updateNote(input: $input) {
    aws_ds
    description
    done
    hidden
    id
    locationSeconds
    noteType
    parentID
    source
    timestamp
    userID
  }
}
`;
export const updateTrack = `mutation UpdateTrack($input: UpdateTrackInput!) {
  updateTrack(input: $input) {
    aws_ds
    description
    id
    latestMix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    latestMixID
    message
    originalID
    otherUserID
    parentID
    placeholderID
    timestamp
    userID
  }
}
`;
