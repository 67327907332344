import React, { useState } from "react";

import SignUpAccount from "./SignUpAccount";
import SignUpConfirm from "./SignUpConfirm";

import "./SignUpPane.css";
import SignInWithAppleButton from "./SignInWithAppleButton";
import SignInWithFacebookButton from "./SignInWithFacebookButton";

export default function SignUpPane(props) {
  const [newUser, setNewUser] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  function onUserCreation(user, email, password) {
    console.log("New User: ", user);
    setNewUser(user);
    setEmail(email);
    setPassword(password);
  }

  return (
    <div className="SignUpPane">
      <h1>Create Account</h1>
      <div className="ContinueButtons">
        <SignInWithAppleButton verb="Continue" />
        <SignInWithFacebookButton verb="Continue" />
      </div>
      <div className="Separator">or</div>
      {newUser ? (
        <SignUpConfirm email={email} password={password} user={newUser} history={props.history} />
      ) : (
        <SignUpAccount onUserCreation={onUserCreation} />
      )}
    </div>
  );
}
