import React, { useEffect, useState, useRef } from "react";
import { API } from "aws-amplify";
import { graphqlOperation } from "aws-amplify";
import { getTrack } from "../graphql/queries";
import { getMix } from "../graphql/queries";
import NoteList from "./NoteList";
import Player from "./Player";
import Loading from "./Loading";

import "./Track.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import ShareSheet from "./ShareSheet";

const Track = (props) => {
  const [track, setTrack] = useState(null);
  const [mix, setMix] = useState(null);
  const { trackID } = props;
  const [showShareSheet, setShowShareSheet] = useState();

  useEffect(() => {
    async function fetchTrackAndMix() {
      try {
        const trackData = await API.graphql(graphqlOperation(getTrack, { id: trackID }));
        const track = trackData.data.getTrack;
        setTrack(track);
        const mixData = await API.graphql(graphqlOperation(getMix, { id: track.latestMixID }));
        const mix = mixData.data.getMix;
        setMix(mix);
      } catch (err) {
        console.log("error fetching track and mix: ", err);
      }
    }
    setTrack(null);
    setMix(null);
    fetchTrackAndMix();
  }, [trackID]);

  const player = useRef(null);

  function onNoteSelected(note) {
    player.current.seekTo(note.locationSeconds, "seconds");
  }

  const [locationSeconds, setLocationSeconds] = useState(0.0);
  function onProgress(progress) {
    setLocationSeconds(progress.playedSeconds);
  }

  function onTrackListClicked(e) {
    if (props.onTrackListClicked) props.onTrackListClicked();
    e.stopPropagation();
  }

  function onShareClicked(e) {
    setShowShareSheet(true);
    e.stopPropagation();
  }

  function renderTrack() {
    return (
      <React.Fragment>
        {mix ? (
          <React.Fragment>
            <Player ref={player} mix={mix} onProgress={onProgress} />
            <NoteList mix={mix} locationSeconds={locationSeconds} onNoteSelected={onNoteSelected} />
          </React.Fragment>
        ) : (
          <Loading />
        )}
        <ShareSheet track={track} mix={mix} show={showShareSheet} onClose={() => setShowShareSheet(false)} />
      </React.Fragment>
    );
  }

  return (
    <div className="Track">
      <div className="TrackTitle">
        <div>
          <Button variant="outline-primary" className="TrackListButton d-lg-none" onClick={onTrackListClicked}>
            <FontAwesomeIcon icon={faCaretLeft} />
          </Button>
        </div>
        <h1>{track ? track.description : ""}</h1>
        <Button className="ShareButton" onClick={onShareClicked} disabled={!track}>
          <FontAwesomeIcon icon={faShareAlt} />
        </Button>
      </div>

      {track ? renderTrack() : trackID ? <Loading /> : <div />}
    </div>
  );
};

export default Track;
