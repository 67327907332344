import React, { useState } from "react";

import "./SplitView.css";
import { Container, Row, Col } from "react-bootstrap";

function SplitView(props) {
  const { showCollapsedLeft } = props;
  console.log("showCollapsedLeft: ", showCollapsedLeft);
  return (
    <div className="SplitView">
      <Container fluid>
        <Row noGutters={true} className="Row">
          <Col
            lg={4}
            md={showCollapsedLeft ? 12 : 0}
            className={"SplitViewColumn Left" + (showCollapsedLeft ? "" : " d-none d-lg-flex")}
          >
            {props.children[0]}
          </Col>
          <Col
            lg={8}
            md={showCollapsedLeft ? 0 : 12}
            className={"SplitViewColumn Right" + (showCollapsedLeft ? " d-none d-lg-flex" : "")}
          >
            {props.children[1]}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SplitView;
