export default {
  "environment": "prod",
  "appSync": {
    "url": "https://vh426kg5qzftpbmavofszne3g4.appsync-api.us-west-2.amazonaws.com/graphql",
    "region": "us-west-2"
  },
  "identityPool": {
    "identityPoolID": "us-west-2:f8f52fe3-a88a-4a54-98e8-b149550984ba",
    "region": "us-west-2"
  },
  "userPool": {
    "userPoolID": "us-west-2_FKV34jVEG",
    "userPoolRegion": "us-west-2",
    "iOSAppID": "66eademv23r20d5f4t3avriben",
    "iOSAppSecret": "11a98ti1d6evmkgdm9bijecligg9db24eghn4kotkp9urbfp45ep",
    "macOSAppID": "460j0qbnmc11bvg6hcmjv9d0e9",
    "macOSSecret": "r7h36pdi939p90cktcelcu8i80chvhr4r3ote243o8it2gnasfn",
    "webAppID": "7n8idf6fah60oi8go9df9bsi7k",
    "cognitoAuthDomain": "id.tracknotes.io"
  },
  "apiGateway": {
    "url": "https://iz66t9pk0c.execute-api.us-west-2.amazonaws.com/prod",
    "region": "us-west-2"
  },
  "storage": {
    "region": "us-west-2",
    "mixBucket": "tracknotes.mix-upload.us-west-2.prod"
  },
  "webOAuth": {
    "redirectSignIn": "https://app.tracknotes.io/",
    "redirectSignOut": "https://app.tracknotes.io/",
    "cognitoAuthDomain": "id.tracknotes.io"
  },
  "analytics": {
    "iOS": {
      "projectID": "851e0da027e04c07a5bd7f04ebf7237f",
      "region": "us-east-1"
    }
  },
  "web": {
    "webShareDomain": "t.tracknotes.io",
    "webAppDomain": "app.tracknotes.io"
  },
  "awsconfiguration": "{\"UserAgent\":\"aws-amplify/cli\",\"Version\":\"0.1.0\",\"IdentityManager\":{\"Default\":{}},\"AppSync\":{\"Default\":{\"ApiUrl\":\"https://vh426kg5qzftpbmavofszne3g4.appsync-api.us-west-2.amazonaws.com/graphql\",\"Region\":\"us-west-2\",\"AuthMode\":\"AMAZON_COGNITO_USER_POOLS\",\"ClientDatabasePrefix\":\"TrackNotes prod us-west-2\"}},\"CredentialsProvider\":{\"CognitoIdentity\":{\"Default\":{\"PoolId\":\"us-west-2:f8f52fe3-a88a-4a54-98e8-b149550984ba\",\"Region\":\"us-west-2\"}}},\"CognitoUserPool\":{\"Default\":{\"PoolId\":\"us-west-2_FKV34jVEG\",\"Region\":\"us-west-2\",\"AppClientId\":\"66eademv23r20d5f4t3avriben\",\"AppClientSecret\":\"11a98ti1d6evmkgdm9bijecligg9db24eghn4kotkp9urbfp45ep\",\"PinpointAppId\":\"851e0da027e04c07a5bd7f04ebf7237f\"}},\"FacebookSignIn\":{\"Permissions\":\"public_profile\"},\"Auth\":{\"Default\":{\"OAuth\":{\"WebDomain\":\"id.tracknotes.io\",\"AppClientId\":\"66eademv23r20d5f4t3avriben\",\"AppClientSecret\":\"11a98ti1d6evmkgdm9bijecligg9db24eghn4kotkp9urbfp45ep\",\"SignInRedirectURI\":\"tracknotes://signin/\",\"SignOutRedirectURI\":\"tracknotes://signout/\",\"Scopes\":[\"phone\",\"email\",\"openid\",\"profile\",\"aws.cognito.signin.user.admin\"]}}},\"S3TransferUtility\":{\"Default\":{\"Bucket\":\"tracknotes.mix-upload.us-west-2.prod\",\"Region\":\"us-west-2\"}},\"PinpointAnalytics\":{\"Default\":{\"AppId\":\"851e0da027e04c07a5bd7f04ebf7237f\",\"Region\":\"us-east-1\"}},\"PinpointTargeting\":{\"Default\":{\"Region\":\"us-east-1\"}}}"
};