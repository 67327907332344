import React from "react";
import Navigation from "../components/Navigation";
import LoginPane from "../components/LoginPane";

import "./Login.css";

function Login(props) {
  return (
    <>
      <Navigation />
      <LoginPane history={props.history} />
    </>
  );
}

export default Login;
