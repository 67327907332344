import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AWSAppSyncClient, AUTH_TYPE } from "aws-appsync";
import { Rehydrated } from "aws-appsync-react";
import { ApolloProvider } from "react-apollo";
import { Auth } from "aws-amplify";

import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import SignUp from "./containers/Signup";
import SharedTrackLanding from "./containers/SharedTrackLanding";

import AppliedRoute from "./components/AppliedRoute";

import "./App.css";

import config from "./config";
import SharedTrackRedeem from "./containers/SharedTrackRedeem";

const client = new AWSAppSyncClient({
  url: config.appSync.url,
  region: config.appSync.region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
});

function App(props) {
  const childProps = {};
  return (
    <ApolloProvider client={client}>
      <Rehydrated>
        <Router>
          <div className="App">
            <Switch>
              <AppliedRoute exact path="/" component={Home} props={childProps} />
              <AppliedRoute path="/login" component={Login} props={childProps} />
              <AppliedRoute path="/signup" component={SignUp} props={childProps} />
              <AppliedRoute path="/t" component={SharedTrackLanding} props={childProps} />
              <AppliedRoute path="/track" component={Home} props={childProps} />
              <AppliedRoute path="/redeem" component={SharedTrackRedeem} props={childProps} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
      </Rehydrated>
    </ApolloProvider>
  );
}

export default App;
