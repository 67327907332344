import React from "react";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NoteListDescriptionColumn(props) {
  const { note } = props;
  switch (note.noteType) {
    case 2: // Thumbs Up
      return (
        <div className="description icon">
          <FontAwesomeIcon icon={faThumbsUp} />
        </div>
      );
    case 3: // Thumbs Down
      return (
        <div className="description icon">
          <FontAwesomeIcon icon={faThumbsDown} />
        </div>
      );
    default:
      return <div className="description">{note.description}</div>;
  }
}

export default NoteListDescriptionColumn;
