/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMixNote = `subscription OnCreateMixNote($parentID: ID!) {
  onCreateMixNote(parentID: $parentID) {
    aws_ds
    description
    done
    hidden
    id
    locationSeconds
    noteType
    parentID
    source
    timestamp
    userID
  }
}
`;
export const onCreateTrack = `subscription OnCreateTrack($userID: ID!) {
  onCreateTrack(userID: $userID) {
    aws_ds
    description
    id
    latestMix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    latestMixID
    message
    originalID
    otherUserID
    parentID
    placeholderID
    timestamp
    userID
  }
}
`;
export const onCreateTrackMix = `subscription OnCreateTrackMix($parentID: ID!, $userID: ID!) {
  onCreateTrackMix(parentID: $parentID, userID: $userID) {
    audioFile {
      bucket
      key
      region
    }
    audioStatus
    aws_ds
    description
    deviceID
    id
    originalID
    otherUserID
    parentID
    source
    timestamp
    userID
  }
}
`;
export const onDeleteMixNote = `subscription OnDeleteMixNote($parentID: ID!, $userID: ID!) {
  onDeleteMixNote(parentID: $parentID, userID: $userID) {
    aws_ds
    description
    done
    hidden
    id
    locationSeconds
    noteType
    parentID
    source
    timestamp
    userID
  }
}
`;
export const onDeleteTrack = `subscription OnDeleteTrack($userID: ID!) {
  onDeleteTrack(userID: $userID) {
    aws_ds
    description
    id
    latestMix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    latestMixID
    message
    originalID
    otherUserID
    parentID
    placeholderID
    timestamp
    userID
  }
}
`;
export const onDeleteTrackMix = `subscription OnDeleteTrackMix($parentID: ID!, $userID: ID!) {
  onDeleteTrackMix(parentID: $parentID, userID: $userID) {
    audioFile {
      bucket
      key
      region
    }
    audioStatus
    aws_ds
    description
    deviceID
    id
    originalID
    otherUserID
    parentID
    source
    timestamp
    userID
  }
}
`;
export const onDeltaAnyMix = `subscription OnDeltaAnyMix($userID: ID!) {
  onDeltaAnyMix(userID: $userID) {
    audioFile {
      bucket
      key
      region
    }
    audioStatus
    aws_ds
    description
    deviceID
    id
    originalID
    otherUserID
    parentID
    source
    timestamp
    userID
  }
}
`;
export const onDeltaFriend = `subscription OnDeltaFriend($userID: ID!) {
  onDeltaFriend(userID: $userID) {
    aws_ds
    contact
    contactType
    id
    initiatedByUserID
    otherUserID
    status
    userID
  }
}
`;
export const onDeltaMixNote = `subscription OnDeltaMixNote($parentID: ID!) {
  onDeltaMixNote(parentID: $parentID) {
    aws_ds
    description
    done
    hidden
    id
    locationSeconds
    noteType
    parentID
    source
    timestamp
    userID
  }
}
`;
export const onDeltaTrack = `subscription OnDeltaTrack($userID: ID!) {
  onDeltaTrack(userID: $userID) {
    aws_ds
    description
    id
    latestMix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    latestMixID
    message
    originalID
    otherUserID
    parentID
    placeholderID
    timestamp
    userID
  }
}
`;
export const onDeltaTrackMix = `subscription OnDeltaTrackMix($parentID: ID!, $userID: ID!) {
  onDeltaTrackMix(parentID: $parentID, userID: $userID) {
    audioFile {
      bucket
      key
      region
    }
    audioStatus
    aws_ds
    description
    deviceID
    id
    originalID
    otherUserID
    parentID
    source
    timestamp
    userID
  }
}
`;
export const onUpdateMixNote = `subscription OnUpdateMixNote($parentID: ID!, $userID: ID!) {
  onUpdateMixNote(parentID: $parentID, userID: $userID) {
    aws_ds
    description
    done
    hidden
    id
    locationSeconds
    noteType
    parentID
    source
    timestamp
    userID
  }
}
`;
export const onUpdateTrack = `subscription OnUpdateTrack($userID: ID!) {
  onUpdateTrack(userID: $userID) {
    aws_ds
    description
    id
    latestMix {
      audioFile {
        bucket
        key
        region
      }
      audioStatus
      aws_ds
      description
      deviceID
      id
      originalID
      otherUserID
      parentID
      source
      timestamp
      userID
    }
    latestMixID
    message
    originalID
    otherUserID
    parentID
    placeholderID
    timestamp
    userID
  }
}
`;
export const onUpdateTrackMix = `subscription OnUpdateTrackMix($parentID: ID!, $userID: ID!) {
  onUpdateTrackMix(parentID: $parentID, userID: $userID) {
    audioFile {
      bucket
      key
      region
    }
    audioStatus
    aws_ds
    description
    deviceID
    id
    originalID
    otherUserID
    parentID
    source
    timestamp
    userID
  }
}
`;
